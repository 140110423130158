import React from "react"
import Layout from "@components/Layout"
import { graphql } from "gatsby"
import SEO from "@components/SEO"
import { Grid, Box, VStack, Heading, Tag } from "@chakra-ui/react"
import PostItem from "@components/PostItem"
import tagProps from "@utils/tagProps"
import dataNormaliser from "@utils/dataNormaliser"

function normaliseQuery(_data) {
  return {
    category: dataNormaliser.normaliseCategory(_data.category),
  }
}

export default function CategoryPage({ data: _data }) {
  const data = normaliseQuery(_data)
  return (
    <Layout>
      <SEO
        title={data.category.title}
        description={data.category.description}
        // ogImage={data.post.mainImage.fluid.src}
      />
      <Box
        border="1px solid"
        borderColor="blue.300"
        bg="blue.800"
        rounded="md"
        overflow="hidden"
        mt="4"
      >
        <Tag
          as="div"
          variant="solid"
          colorScheme={data.category.color}
          {...tagProps(data.category)}
          h="8"
          _hover={{}}
          w="100%"
          rounded="none"
        />
        <Box px="8" py="6">
          <Heading as="h1" size="lg" fontWeight="bold">
            {data.category.title}
          </Heading>
          {data.category.description && (
            <Box mt="6">{data.category.description}</Box>
          )}
        </Box>
      </Box>
      <Grid
        py="4"
        gap="4"
        gridTemplateColumns={[`100%`, null, null, `2fr 5fr`, `240px 2fr 1fr`]}
      >
        <Box>
          <aside aria-label="Primary sidebar"></aside>
        </Box>
        <Box>
          <main role="main">
            <VStack spacing="2" align="start">
              <Heading as="span" fontSize="1.5rem" fontWeight="bold">
                Posts
              </Heading>
              {data.category.posts.map((post, index) => (
                <PostItem
                  post={post}
                  key={`${post.slug}-${index}`}
                  showImage={index === 0}
                />
              ))}
            </VStack>
          </main>
        </Box>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query CategoryQuery($slug: String) {
    category: sanityCategory(slug: { current: { eq: $slug } }) {
      title
      posts {
        ...PostItem
      }
      color_custom {
        hex
      }
      color
      description
    }
  }
`
